.time-slots-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: center;
}

.time-slots-box {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.time-slots-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.time-slot-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.time-slot-button.available:hover {
    background-color: #218838;
}

.time-slot-button.booked {
    background-color: #b61919;
    cursor: not-allowed;
}

.booking-details {
    margin-top: 30px;
    text-align: left;
}

.btn-back {
    margin-top: 20px;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-back:hover {
    background-color: #0056b3;
}
