.container {
	max-width: 1500px;
	width: 100%;
	margin: 20px auto;
	padding: 0 20px;
	position: relative;
	border-radius: 16px;
	background-color: #ffffff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calendar-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	padding: 10px;
	background-color: #ffffff;
	border-radius: 8px;
}

.calendar-view-toggle {
	display: flex;
	gap: 10px;
}

.view-calendar-button {
	padding: 10px 15px;
	font-size: 1rem;
	cursor: pointer;
	background-color: #14304b;
	color: #ffffff;
	border: none;
	border-radius: 4px;
	transition: background-color 0.3s ease;
}

.view-calendar-button.active {
	background-color: #14304b;
}

.view-calendar-button:hover {
	background-color: #0d1f31;
}

.professor-select-container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.professor-select-label {
	font-weight: bold;
}

.professor-select-dropdown {
	padding: 8px;
	font-size: 1rem;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.custom-modal {
	display: block;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.5);
}

/* Modal content */
.custom-modal-content {
	background-color: white;
	margin: 10% auto;
	padding: 20px;
	border: 1px solid #888;
	width: 90%;
	max-width: 500px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
	text-align: center;
}

/* Event details styling */
.event-details {
	margin-top: 20px;
}

.event-title {
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: bold;
}

.event-text {
	font-size: 16px;
	margin-bottom: 10px;
}

/* Delete button */
.delete-button {
	margin-top: 20px;
	background-color: #dc3545;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.delete-button:hover {
	background-color: #c82333;
}

/* Close button */
.close {
	color: #000000;
	float: right;
	font-size: 28px;
	font-weight: bold;
	cursor: pointer;
}

.close:hover,
.close:focus {
	color: #0056b3; /* Blue for hover effect */
	text-decoration: none;
	cursor: pointer;
}

/* Form styling */
.form-group {
	margin-bottom: 15px;
}

.form-group label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}

.form-control {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	border: 1px solid #ccc;
	border-radius: 4px;
	transition: border-color 0.3s ease;
}

.form-control:focus {
	border-color: #007bff;
	outline: none;
}

.btn {
	background-color: #007bff;
	color: rgb(255, 255, 255);
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.btn:hover {
	background-color: #0056b3;
}

.fc-header-toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .fc-header-toolbar {
    flex-direction: column;
    align-items: center;
  }
}

button {
	transition: background-color 0.3s ease, color 0.3s ease;
}

button:focus {
	outline: none;
}
