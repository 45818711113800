.group-manager-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
}

.group-manager-header,
.existing-groups-header {
    text-align: center;
    margin-bottom: 20px;
}

.group-creation-box,
.your-groups-box {
    margin-bottom: 30px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.group-manager-form {
    margin-bottom: 20px;
}

.group-input {
    width: 96.5%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 15px;
}

.group-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-inline {
    display: flex;
    gap: 10px;
}

.group-select {
    flex-grow: 1;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.btn {
    padding: 10px 15px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.btn-add-member {
    background: #007bff;
}

.btn-create-group {
    width: 100%;
    padding: 12px;
    background: hsl(134, 61%, 41%);
    
}

.group-members {
    margin-bottom: 20px;
}

.group-members-header {
    font-weight: bold;
}

.group-members-list {
    padding-left: 20px;
    line-height: 1.8;
}

.current-user {
    font-weight: bold;
}

.group-item {
    padding: 15px;
    border-bottom: 1px solid #eee;
    position: relative;
}

.group-info {
    padding-left: 10px;
    margin-bottom: 15px;
}

.btn-leave-group {
    background: #dc3545;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: -230px; 
}

.existing-groups-list {
    padding-left: 20px;
    line-height: 1.8;
}

.group-member-item {
    display: flex;
    align-items: center;
    gap: 1px;
    margin-bottom: 5px;
}

.remove-member {
    cursor: pointer;
    color: red;
    font-weight: bold;
    opacity: 0.7;
    margin-right: 1px;
    transition: opacity 0.2s;
}

.remove-member:hover {
    opacity: 1;
}

/* Fields for Join Code stuff */
#teamJoinHeader{
    margin: auto;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    font-size: 20px;
    margin: 5px
}

#teamJoinContent{
    margin: auto;
    text-align: center;
}

#popupContainer{
    position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
}

#popupInnerContainer{
    position: relative;
	padding: 32px;
	width: 100%;
	max-width: 640px;
	background-color: #fff;
}

#openPopupButton{
    width: 80%;
	padding: 10px;
	justify-content: center;
	align-items: center;
	display: flex;
	margin: auto;
	font-weight: bold;
	cursor: pointer;
	background: #5dbea3;
}

#closePopupButton{
    position: absolute;
	top: 16px;
	right: 16px;
	background: #5dbea3;
	padding: 5px;
}

#joinButton{
    background: #5dbea3;
    border: none;
    padding:8px;
    width: 45px;
}

#joinInput{
    padding: 7px;
}

#joinInputForm{
    justify-content: center;
	align-items: center;
    display:flex;
    margin: auto;
    padding: 10px;
}