header {
	height: 150px;
	background-color: white;
	padding: 10px;
	font-size: 250%;
	text-align: center;
	background-size: 150px;
	background-position: center;
	background-repeat: no-repeat;
	color: #ffffff;
	width: 100%;
	align-items: center;
}
#logoBox {
	background-image: url(../../Images/Sacramento-State-University-logo_stacked.png);
	background-size: contain;
	height: 125px;
	padding: 10px;
	text-align: center;
	background-position: center;
	background-repeat: no-repeat;
	color: #000000;
	margin: 0 auto;
	width: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
}
