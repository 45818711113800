#loginForm {
	margin: 0 auto;
	width: fit-contents;
	padding: 20px;
	border: 1px solid #043927;
	border-radius: 10px;
	background-color: #c4b581;
	margin-top: 10%;
}
body {
	background-image: url(../Images/LoginPageImage.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
}

p {
	text-decoration: underline;
}
h2 {
	text-align: right;
}

.loginContents {
	align-items: center;
}

#loginError {
	display: none;
	color: red;
}
