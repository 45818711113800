#CreateAccountForm{
  margin: 0 auto;
  width: fit-content;
  padding: 20px;
  border: 1px solid #043927;
  border-radius: 10px;
  background-color: #C4B581;
  margin-top: 10%;
}

.createAccountContents{
  align-items: center;
}

#createAccountError {
  display: none;
}
