/* ProfessorScheduling.css */

.scheduling-container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.scheduling-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
}

.form-container {
    margin-bottom: 2rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #333;
}

.form-control {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

.btn {
    display: block;
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3;
}

.created-slots-container {
    margin-top: 2rem;
}

.created-slots-container h3 {
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
}

.slots-list {
    list-style-type: none;
    padding: 0;
}

.slot-item {
    padding: 1rem;
    background-color: #e9ecef;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.slot-item strong {
    color: #333;
}
