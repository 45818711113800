#loginForm{
    margin: 0 auto;
    width: 50%;
    padding: 10px;
    border: 1px solid #043927;
    border-radius: 10px;
    background-color: #C4B581;
    margin-top: 10%;

}
header {
    height: 150px;
    background-color: white;
    padding: 10px;
    font-size: 250%;
    text-align: center;
    background-size: 150px;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;
    width: 100%;
    align-items: center;
  }

p {
   text-decoration: underline;
}
h2 {
   text-align: right;
}

 .loginContents{
    align-items: center;
 }

 #loginError {
    display: none;
 }